// Color system
$black: #000000;
$white: #ffffff;

$theme-colors: (
    "primary":    #44d7b6
);

$enable-caret: false;

// Body
//
// Settings for the `<body>` element.

$body-bg:               $white;
$body-color:            $black;

// Typography

$font-family-base:      'Poppins', sans-serif;

$font-weight-light:     300;
$font-weight-regular:   400;
$font-weight-medium:    500;
$font-weight-semibold:  600;
$font-weight-bold:      700;

$font-weight-base:      $font-weight-regular;

$headings-font-weight:  $font-weight-bold;

