.contact-social-links {
    .nav-link {
        padding: 0 8px;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.contact-page-wrapper {
    min-height: calc(100vh - 188px);
    padding-top: 50px;
    padding-bottom: 100px;

    @media (min-width: 768px) {
        padding-top: 100px;
        padding-bottom: 100px;
        display: flex;
        align-items: center;
    }
}

.contact-page-title {
    font-size: 25px;
    font-weight: $font-weight-bold;
    line-height: 1;
    color: theme-color();
    margin-bottom: 30px;
}

.contact-page-street-address {
    margin-bottom: 28px;
}

.contact-page-content-wrapper {
    display: flex;
    padding-bottom: 75px;
    border-bottom: 1px solid #d3d3d3;
    @media (min-width: 768px) {
        padding-bottom: 0;
        border-bottom: 0;
        border-right: 1px solid #d3d3d3;
    }
}

.contact-page-form-wrapper {
    padding-top: 100px;

    @media (min-width: 768px) {
        padding-top: 0;
        padding-left: 98px;
    }

    .form-group {
        margin-bottom: 42px;
    }

    .form-control {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #d3d3d3;
        padding: 5px 0;
        font-size: 16px;
        font-weight: $font-weight-medium;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &::placeholder {
            color: #d3d3d3;
        }
    }

    .btn {
        font-size: 25px;
        font-weight: $font-weight-medium;
        line-height: 1;
        color: #000000;
        padding: 0;
    }
}